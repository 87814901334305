<template>
  <div class="header-nav">
    <v-btn
      text
      class="mr-3"
      link
      to="/services/servers"
      :color="$route.path.includes('servers') ? 'white' : ''"
    >
      Servers
    </v-btn>
    <v-btn
      text
      class="mr-3"
      link
      to="/services/pods"
      :color="$route.path.includes('pods') ? 'white' : ''"
    >
      Pods
    </v-btn>
  </div>
</template>

<script>
  export default {};
</script>
<style scoped>
  .header-nav {
    width: 100%;
    padding: 20px;
    margin: 20px 0;
    background-color: #1E1E1E;
    border-radius: 4px;
  }

</style>
