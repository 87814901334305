<template>
  <div class="monitoring-page-wrapper">
    <div>
      <services-tabs />
    </div>
    <div class="srt-info-container">
      <p class="ma-0">SRT Machines: {{ srtMachines.length }}</p>
    </div>
    <v-data-table
      class="mb-6 pods-table"
      :items="pods"
      sort-by="id"
      :headers="podsHeaders"
      item-key="id"
      show-expand
      :expanded.sync="expandedPods"
      :items-per-page="-1"
    >

      <template v-slot:item.places="{ item }">
        <span>
          {{ item.places.filter(x => x.userId != null).length }} / {{ item.places.length }}
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">

        <v-data-table
          :items="item.places.filter(x => x.userId)"
          :headers="podsExpandedHeaders"
          hide-default-footer
          :items-per-page="-1"
          no-data-text="No users on this pod"
        >

          <template v-slot:item.userId="{ item }">
            <span>
              {{ users.get(item.userId).username }}
            </span>
          </template>

          <template v-slot:item.url="{ item }">
            <span>
              {{ users.get(item.userId).avatar ? users.get(item.userId).avatar : '---'  }}
            </span>
          </template>

          <template v-slot:item.status="{ item }">
            <span :class="`${users.get(item.userId).status ? 'green':'red'}`" class="text-no-wrap">
              {{ users.get(item.userId).status ? 'STREAMING' : 'NOT STREAMING' }}
            </span>
          </template>

        </v-data-table>
      </template>
    </v-data-table>
  </div>
</template>


<script>
  import ServicesTabs from './ServicesTabs'
  import request from "@/plugins/request";
  import { Constants } from '@/config/constants';

  export default {
    components: {
      ServicesTabs
    },
    data() {
      return {
        pods: [],
        expandedPods: [],
        podsHeaders: [
          {
            text: "Id",
            sortable: false,
            align: "left",
            value: "id",
            width: "45%"
          },
          {
            text: "Places",
            sortable: false,
            align: "leftpressed",
            value: "places",
            width: "45%"
          },
          {
            text: '',
            value: 'data-table-expand',
            align: "right",
            width: "10%"
          }

        ],
        podsExpandedHeaders: [
          {
            text: "Username",
            sortable: false,
            align: "left",
            value: "userId",
            width: "25%"
          },
          {
            text: "Avatar Url",
            sortable: false,
            align: "left",
            value: "url",
            width: "50%"
          },
          {
            text: "Status",
            sortable: false,
            align: "right",
            value: "status",
            width: "25%"
          },
        ],
        users: new Map(),
        userAvatars: [],
        allStreams: [],
        srtMachines: [],
        infoInterval: null,

      }
    },
    mounted() {
      const self = this;
      this.getInfo();

      self.infoInterval = setInterval(function () {
        self.getInfo();
      }, 10000);
    },
    beforeDestroy() {
      clearInterval(this.infoInterval);
    },
    methods: {
      async getInfo() {

        // this.pods = [];
        this.users = new Map();
        this.allStreams = [];
        this.userAvatars = [];

        await this.getStreams();
        await this.getAvatarsInfo();
        await this.getPodsInfo();
      },

      async getStreams() {
        const self = this;
        request({ method: 'GET', url: '/v1.0/clients/prototype/streams' })
          .then(res => {
            self.srtMachines = res.data.data.machines;
            res.data.data.machines.map(machine => {
              self.allStreams.push(...machine.streams.filter(x => x.isStreaming === true));
            });
          })
      },

      async getAvatarsInfo() {
        const self = this;
        request({ method: 'GET', url: '/concert/api/v1/Scene/GetUserAvatars' })
          .then(res => {
            self.userAvatars = res.data;
          })
      },

      async getPodsInfo() {
        const self = this;
        request({ method: 'GET', url: '/concert/api/Nats/GetSnapshotByConcertId?concertId=' + Constants.DefaultConcertId })
          .then(res => {
            self.pods = res.data.pods;

            for (const user in res.data.users) {
              let avatarUrl = self.userAvatars.find(x => x.userId === user) ? self.userAvatars.find(x => x.userId === user).avatar : '';
              let streamStatus = false;
              let stream = self.allStreams.find(x => x.idClient === user);

              if (stream) {
                streamStatus = true;
              }

              self.users.set(user, { username: res.data.users[user], avatar: avatarUrl, status: streamStatus });
            }
          })
      },
    },
  }
</script>

<style lang="scss">
.monitoring-page-wrapper {
  padding: 100px 70px 0;
  align-items: center;
}

.pods-table {
  .v-icon {
    &:after {
      background-color: transparent !important;
    }
  }
}

.srt-info-container {
  background-color: #1E1E1E;
  padding: 10px;
  border-radius: 4px;
  margin: 15px 0;
}
</style>
